import "../components/base.css";
import { Link } from "gatsby";
import Layout from "../components/layout";
import React from "react";

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <br></br>
          <h1>&gt; QUICK LINKS</h1>
          <br></br>
          <h2><Link to="../quick-links/rsi-official/" title="RSI Official">RSI OFFICIAL /</Link></h2>
          <br></br>
          <h2><Link to="../quick-links/community-tools/" title="Community Tools">COMMUNITY TOOLS /</Link></h2>
          <br></br>
          <h2><Link to="../quick-links/streamer-tv/" title="Streamer TV">STREAMER TV /</Link></h2>
          <br></br>
          <h2><Link to="../quick-links/how-to/" title="How To">HOW TO GUIDES /</Link></h2>
          </div>
          <br></br>
      
      </Layout>
    );
  }
}

